import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Menu from 'react-burger-menu/lib/menus/push';

// Components
import { Col } from '../global/col';
import { Row } from '../global/row';
import SlideOutCart from './slide-out-cart';
import Logo from '../icons/logo';
import CartNavigationWrapper from '../cart/cart-navigation-wrapper';
import Search from '../search/search';

const Header = styled.header`
  width: 100%;
  line-height: 80px;

  box-shadow: 0px 1px 1px rgba(50, 50, 50, 0.1);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  transform: translateY(${props => (props.isShowing ? '0' : '-100%')});
  transition: 250ms transform ease;

  z-index: 10000;

  background-color: #fff;

  padding: 0 60px;

  @media (max-width: 1300px) {
    padding: 0 30px;
  }
  @media (max-width: 1220px) {
    padding: 0 20px;
  }

  & a,
  button {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
    font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;
    text-transform: capitalize;

    font-size: 14px;
    letter-spacing: 0.02em;
  }

  & a {
    color: #323232;
  }

  & a:hover,
  & button:hover {
    color: #934e2a;
  }
`;

const Navbar = styled.nav`
  width: 100%;

  & .grid-container {
    display: grid;
    grid-template-columns: 1fr 330px 1fr;
    column-gap: 15px;
  }
`;

const FlexMenu = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${props => props.justifyContent};
  align-items: center;
`;

const CollectionsMenu = styled.ul`
  margin: 0;
  border: 0;

  padding: 0 60px;

  @media (max-width: 1200px) {
    padding: 0 30px;
  }

  box-shadow: 0px 1px 1px rgba(50, 50, 50, 0.1);
  border-top: 1px solid #e8e8e8;

  display: ${props => (props.open === true ? 'flex' : 'none')};
  flex-direction: row;
  flex-wrap: wrap;

  & li {
    list-style: none;
    margin-right: 30px;
    @media (max-width: 1200px) {
      margin-right: 20px;
    }
  }

  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  width: 100%;

  background-color: #fff;
`;

const MenuLeft = styled.div`
  & button,
  & a {
    margin-right: 20px;
    /* @media (max-width: 1200px) {
      margin-right: 20px;
    } */
  }

  & a {
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const MenuRight = styled.div`
  & a {
    margin-right: 30px;
    @media (max-width: 1200px) {
      margin-right: 20px;
    }
  }
`;

const Toggle = styled.button`
  pointer-events: ${props => (props.disableClick ? 'none' : 'all')};

  color: ${props => (props.open ? '#934E2A' : '#323232')};

  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const CartToggle = styled.button`
  margin: 0 !important;

  line-height: 1;

  pointer-events: ${props => (props.disableClick ? 'none' : 'all')};

  @media (max-width: 768px) {
    right: 20px;
  }

  @media (max-width: 374px) {
    right: 10px;
  }
`;

const DownArrow = styled.div`
  box-sizing: border-box;
  height: 6px;
  width: 6px;
  border-style: solid;
  border-color: rgba(50, 50, 50, 0.4);
  border-width: 0px 1px 1px 0px;
  transform: ${props =>
    props.open === true ? 'rotate(-135deg)' : 'rotate(45deg)'};
  transition: transform 15ms linear;

  display: block;
  margin-left: 5px;
  margin-top: 13px;
`;

class DesktopMenuComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      isCollectionsMenuOpen: false,
      cartOpen: false,
      prevScrollpos: 0,
      showMenu: true,
      isSearchOpen: false,
    };

    this.toggleIsOpenSearch = this.toggleIsOpenSearch.bind(this);
    this.toggleCollectionsMenu = this.toggleCollectionsMenu.bind(this);
    this.closeAllMenus = this.closeAllMenus.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const showMenu =
      currentScrollPos <= 100 || prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      showMenu,
    });
  }

  toggleCollectionsMenu = () => {
    this.setState(prevState => ({
      isCollectionsMenuOpen: !prevState.isCollectionsMenuOpen,
    }));
  };

  closeAllMenus = () => {
    this.setState({
      isCollectionsMenuOpen: false,
    });
  };

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleCartStateChange(state) {
    this.setState({
      cartOpen: state.isOpen,
    });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeCart() {
    this.setState({ cartOpen: false });
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleCart() {
    this.setState(state => ({
      cartOpen: !state.cartOpen,
    }));
  }

  // Toggle the search menu open / closed
  toggleIsOpenSearch = () => {
    this.setState(
      state => ({
        isSearchOpen: !state.isSearchOpen,
      }),
      () => {
        if (this.state.isSearchOpen === true) {
          document.getElementById('search-input').focus();
        }
      }
    );
  };

  render() {
    const collectionsMenuLinks = this.props.data.prismicCollectionsMenu.data.menu.map(
      (item, index) => (
        <li key={index}>
          <Link
            to={`/collections/${item.link}`}
            activeStyle={{ color: '#934E2A' }}
            onClick={this.toggleCollectionsMenu}
          >
            {item.name}
          </Link>
        </li>
      )
    );

    const leftMenuLinks = this.props.data.prismicMenus.data.header_left_side.map(
      (item, index) => {
        if (item.header_left_link.link_type === 'Document') {
          return (
            <Link
              to={item.header_left_link.document.url}
              activeStyle={{ color: '#934E2A' }}
              key={`single_left_menu_${index}_${item.header_left_link.document.id}`}
            >
              {item.header_left_link.document.data.title.text}
            </Link>
          );
        } else {
          return (
            <a
              href={item.header_left_link.url}
              key={`single_left_menu_${index}_external`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.link_title}
            </a>
          );
        }
      }
    );

    const rightMenuLinks = this.props.data.prismicMenus.data.header_right_side
      // .filter(item => item.header_right_link.document !== null)
      .map((item, index) => {
        if (item.header_right_link.link_type === 'Document') {
          return (
            <Link
              to={item.header_right_link.document.url}
              activeStyle={{ color: '#934E2A' }}
              key={`single_left_menu_${index}_${item.header_right_link.document.id}`}
            >
              {item.header_right_link.document.data.title.text}
            </Link>
          );
        } else {
          return (
            <a
              href={item.header_right_link.url}
              key={`single_left_menu_${index}_external`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.link_title}
            </a>
          );
        }
      });

    return (
      <React.Fragment>
        <Header
          isShowing={this.state.showMenu}
          onMouseLeave={this.closeAllMenus}
        >
          <Navbar>
            <div className="grid-container">
              {/* <Row> */}
              {/* <Col col={12} md={4}> */}
              <MenuLeft>
                <FlexMenu justifyContent={'flex-start'}>
                  <Toggle
                    onClick={this.toggleCollectionsMenu}
                    onMouseEnter={this.toggleCollectionsMenu}
                    disableClick={this.state.cartOpen}
                    open={this.state.isCollectionsMenuOpen}
                  >
                    Collections
                    <DownArrow open={this.state.isCollectionsMenuOpen} />
                  </Toggle>

                  {leftMenuLinks}

                  {/* <Toggle
                      disableClick={this.state.cartOpen}
                      open={this.state.isMourneWeaversMenuOpen}
                    >
                      <Link to={'/projects'} activeStyle={{ color: '#934E2A' }}>
                        Projects
                      </Link>
                    </Toggle>
                    <Link to={'/journal'} activeStyle={{ color: '#934E2A' }}>
                      Journal
                    </Link> */}
                </FlexMenu>
              </MenuLeft>
              {/* </Col> */}

              {/* <Col col={12} md={4}> */}
              <FlexMenu justifyContent={'center'}>
                <Link to={'/'}>
                  <Logo />
                </Link>
              </FlexMenu>
              {/* </Col> */}

              {/* <Col col={12} md={4} alignSelf="end"> */}
              <MenuRight>
                <FlexMenu justifyContent={'flex-end'}>
                  {this.state.isSearchOpen === false && (
                    <>
                      {rightMenuLinks}
                      {/* <Link to={'/about'} activeStyle={{ color: '#934E2A' }}>
                          About
                        </Link>

                        <Link
                          to={'/gallery'}
                          activeStyle={{ color: '#934E2A' }}
                        >
                          Gallery
                        </Link>

                        <Link
                          to={'/contact'}
                          activeStyle={{ color: '#934E2A' }}
                        >
                          Contact
                        </Link> */}

                      <CartToggle
                        onClick={() => this.toggleCart()}
                        disableClick={this.state.isCollectionsMenuOpen}
                      >
                        <CartNavigationWrapper />
                      </CartToggle>
                    </>
                  )}

                  <Search
                    searchIndex={this.props.data.siteSearchIndex.index}
                    isSearchOpen={this.state.isSearchOpen}
                    onClick={() => this.toggleIsOpenSearch()}
                  />
                </FlexMenu>
              </MenuRight>
              {/* </Col> */}
              {/* </Row> */}
            </div>

            <CollectionsMenu open={this.state.isCollectionsMenuOpen}>
              {collectionsMenuLinks}
            </CollectionsMenu>
          </Navbar>
        </Header>

        <Menu
          isOpen={this.state.cartOpen}
          onStateChange={state => this.handleCartStateChange(state)}
          pageWrapId={'page-wrap'}
          outerContainerId={'___gatsby'}
          right
          width={400}
        >
          <SlideOutCart />
        </Menu>
      </React.Fragment>
    );
  }
}

export default DesktopMenuComponent;
