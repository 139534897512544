import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Menu from 'react-burger-menu/lib/menus/push';

// Components
import SlideOutCart from './slide-out-cart';
import Logo from '../icons/logo';
import CartNavigationWrapper from '../cart/cart-navigation-wrapper';
import MobileSearch from '../search/mobile-search';
import './hamburger.css';

const Header = styled.header`
  width: 100%;
  line-height: 60px;

  box-shadow: 0px 1px 1px rgba(50, 50, 50, 0.1);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 10000;

  background-color: #fff;

  padding: 0 60px;
  @media (max-width: 1200px) {
    padding-right: 30px;
    padding-left: 30px;
  }

  @media (max-width: 768px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  & a {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;
    font-feature-settings: 'tnum' on, 'lnum' on, 'salt' on;
    text-transform: capitalize;

    font-size: 15px;
    letter-spacing: 0.02em;
  }

  & button {
    margin-right: 30px;

    @media (max-width: 1200px) {
      margin-right: 20px;
    }
  }
`;

const Navbar = styled.nav`
  width: 100%;
`;

const FlexMenu = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }

  & > a:nth-child(4) {
    margin-right: 0;
    line-height: 1;
  }
`;

const MobileMenuToggle = styled.button`
  position: absolute;
  top: 10px;
  left: 20px;

  padding: 10px;
  line-height: 1 !important;

  margin: 0 !important;

  pointer-events: ${props => (props.disableClick ? 'none' : 'all')};
  background: transparent;
  color: #323232 !important;

  @media (max-width: 768px) {
    left: auto;
    right: 96px;
  }

  @media (max-width: 500px) {
    padding: 10px 5px;
    right: 93px;
  }

  @media (max-width: 374px) {
    padding: 10px 5px;

    right: 70px;
  }
`;

const MobileMenuContainer = styled.div`
  & ul {
    padding: 0;
    margin: 0;

    & li {
      list-style: none;
    }
  }
`;

const Toggle = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;

  & > a {
    padding: 0 !important;
  }
`;

const CollectionsMobileMenu = styled.ul`
  margin: 0;
  border: 0;
  padding: 0;

  max-height: ${props => (props.open === true ? '427px' : '0')};
  transition: max-height 0.5s ease 0s;
  will-change: max-height;
  overflow-y: scroll;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  border-bottom: 1px solid rgba(50, 50, 50, 0.1);

  & li {
    list-style: none;
    padding: 0;
    box-shadow: none;

    width: 100%;

    & a {
      font-size: 16px;
      line-height: 50px;
      border-bottom: 0;
    }
  }

  width: 100%;

  & a:hover,
  & button:hover {
    color: #934e2a;
  }
`;

const CartToggle = styled.button`
  position: absolute;
  right: 63px;

  margin: 0 !important;
  line-height: 1;

  padding: 10px;

  pointer-events: ${props => (props.disableClick ? 'none' : 'all')};

  background-color: transparent;

  @media (max-width: 768px) {
    right: 50px;
  }

  @media (max-width: 500px) {
    top: 7.5px;
    right: 49px;

    padding: 10px 5px;
  }

  @media (max-width: 374px) {
    right: 30px;
  }
`;

const DownArrowButton = styled.span`
  border-bottom: none !important;
  padding: 20px !important;
  width: 50px !important;
`;

const DownArrow = styled.span`
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  border-style: solid;
  border-color: rgba(50, 50, 50, 0.4);
  border-width: 0px 2px 2px 0px;
  transform: ${props =>
    props.open === true ? 'rotate(-135deg)' : 'rotate(45deg)'};
  transition: transform 15ms linear;

  display: block;
`;

class MobileMenuComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      isMobileCollectionsMenuOpen: false,
      menuOpen: false,
      cartOpen: false,
      isSearchOpen: false,
    };

    this.toggleMobileCollectionsMenu = this.toggleMobileCollectionsMenu.bind(
      this
    );
    this.toggleIsOpenSearch = this.toggleIsOpenSearch.bind(this);
  }

  toggleMobileCollectionsMenu = () => {
    this.setState(
      prevState => ({
        isMobileCollectionsMenuOpen: !prevState.isMobileCollectionsMenuOpen,
      }),
      () => {
        if (this.state.isMobileCollectionsMenuOpen === false) {
          this.toggleMenu();
        }
      }
    );
  };

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleMenuStateChange(state) {
    this.setState({
      menuOpen: state.isOpen,
      isMobileCollectionsMenuOpen: false,
    });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({
      menuOpen: false,
      isMobileCollectionsMenuOpen: false,
    });
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu() {
    this.setState(state => ({
      menuOpen: !state.menuOpen,
      isMobileCollectionsMenuOpen: false,
    }));
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleCartStateChange(state) {
    this.setState({
      cartOpen: state.isOpen,
    });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeCart() {
    this.setState({ cartOpen: false });
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleCart() {
    this.setState(state => ({
      cartOpen: !state.cartOpen,
    }));
  }

  // Toggle the search menu open / closed
  toggleIsOpenSearch = () => {
    this.setState(
      state => ({
        isSearchOpen: !state.isSearchOpen,
      }),
      () => {
        if (this.state.isSearchOpen === true) {
          document.getElementById('search-input').focus();
        }
      }
    );
  };

  render() {
    const collectionsMenuLinks = this.props.data.prismicCollectionsMenu.data.menu.map(
      (item, index) => (
        <li key={index}>
          <Link
            to={`/collections/${item.link}`}
            activeStyle={{ color: '#934E2A' }}
            onClick={this.toggleMobileCollectionsMenu}
          >
            {item.name}
          </Link>
        </li>
      )
    );

    const leftMenuLinks = this.props.data.prismicMenus.data.header_left_side.map(
      (item, index) => {
        if (item.header_left_link.link_type === 'Document') {
          return (
            <li
              key={`single_left_menu_${index}_${item.header_left_link.document.id}`}
              onClick={() => this.toggleMenu()}
            >
              <Link
                to={item.header_left_link.document.url}
                activeStyle={{ color: '#934E2A' }}
              >
                {item.header_left_link.document.data.title.text}
              </Link>
            </li>
          );
        } else {
          return (
            <li
              key={`single_left_menu_${index}_external`}
              onClick={() => this.toggleMenu()}
            >
              <a
                href={item.header_left_link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.link_title}
              </a>
            </li>
          );
        }
      }
    );

    const rightMenuLinks = this.props.data.prismicMenus.data.header_right_side.map(
      (item, index) => {
        if (item.header_right_link.link_type === 'Document') {
          return (
            <li
              key={`single_right_menu_${index}_${item.header_right_link.document.id}`}
              onClick={() => this.toggleMenu()}
            >
              <Link
                to={item.header_right_link.document.url}
                activeStyle={{ color: '#934E2A' }}
              >
                {item.header_right_link.document.data.title.text}
              </Link>
            </li>
          );
        } else {
          return (
            <li
              key={`single_right_menu_${index}_external`}
              onClick={() => this.toggleMenu()}
            >
              <a
                href={item.header_right_link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.link_title}
              </a>
            </li>
          );
        }
      }
    );

    return (
      <React.Fragment>
        <Menu
          isOpen={this.state.menuOpen}
          onStateChange={state => this.handleMenuStateChange(state)}
          pageWrapId={'page-wrap'}
          outerContainerId={'___gatsby'}
        >
          <ul>
            {/* <li> */}
            {/* <MobileSearch
              searchIndex={this.props.data.siteSearchIndex.index}
              isSearchOpen={this.state.isSearchOpen}
              onClick={() => this.toggleIsOpenSearch()}
              closeMenu={() => this.toggleMenu()}
            /> */}
            {/* </li> */}
            <li>
              <Toggle onClick={this.toggleMobileCollectionsMenu}>
                Collections
                <DownArrowButton>
                  <DownArrow open={this.state.isMobileCollectionsMenuOpen} />
                </DownArrowButton>
              </Toggle>

              <CollectionsMobileMenu
                open={this.state.isMobileCollectionsMenuOpen}
              >
                {collectionsMenuLinks}
              </CollectionsMobileMenu>
            </li>
            {leftMenuLinks}
            {rightMenuLinks}
            {/*
            <li>
              <Link
                to={'/projects'}
                activeStyle={{ color: '#934E2A' }}
                onClick={() => this.toggleMenu()}
              >
                Projects
              </Link>
            </li>

            <li>
              <Link
                to={'/journal'}
                activeStyle={{ color: '#934E2A' }}
                onClick={() => this.toggleMenu()}
              >
                Journal
              </Link>
            </li>

            <li>
              <Link
                to={'/about'}
                activeStyle={{ color: '#934E2A' }}
                onClick={() => this.toggleMenu()}
              >
                About
              </Link>
            </li>

            <li>
              <Link
                to={'/gallery'}
                activeStyle={{ color: '#934E2A' }}
                onClick={() => this.toggleMenu()}
              >
                Gallery
              </Link>
            </li>

            <li>
              <Link
                to={'/contact'}
                activeStyle={{ color: '#934E2A' }}
                onClick={() => this.toggleMenu()}
              >
                Contact
              </Link>
            </li> */}
          </ul>
        </Menu>

        <Header>
          <Navbar>
            <MobileMenuContainer>
              <FlexMenu>
                <MobileMenuToggle
                  onClick={() => this.toggleMenu()}
                  disableClick={this.state.cartOpen}
                  className={`hamburger hamburger--spring ${this.state
                    .menuOpen && `is-active`}`}
                  type="button"
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </MobileMenuToggle>

                <Link to={'/'}>
                  <Logo />
                </Link>

                <CartToggle
                  onClick={() => this.toggleCart()}
                  disableClick={this.state.menuOpen}
                >
                  <CartNavigationWrapper />
                </CartToggle>

                <MobileSearch
                  searchIndex={this.props.data.siteSearchIndex.index}
                  isSearchOpen={this.state.isSearchOpen}
                  onClick={() => this.toggleIsOpenSearch()}
                  closeMenu={() => this.toggleMenu()}
                />
              </FlexMenu>
            </MobileMenuContainer>
          </Navbar>
        </Header>

        <Menu
          isOpen={this.state.cartOpen}
          onStateChange={state => this.handleCartStateChange(state)}
          pageWrapId={'page-wrap'}
          outerContainerId={'___gatsby'}
          right
        >
          <SlideOutCart />
        </Menu>
      </React.Fragment>
    );
  }
}

export default MobileMenuComponent;
